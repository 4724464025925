var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"}},[_c('v-card',{staticStyle:{"width":"100%"}},[_c('v-list',[_c('v-list-item-group',[_c('v-list-item',{on:{"click":function($event){return _vm.$router.push({
              name: 'My Feedstocks',
              params: { toSelectFeedstock: _vm.toSelectFeedstock }
            })}}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s('mdi-star')}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s('My Feedstocks')}})],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1),_vm._l((_vm.feedstocksCategories),function(category){return _c('v-list-item',{key:category.id,on:{"click":function($event){return _vm.$router.push({
              name: 'Material',
              params: {
                categoryId: category.id,
                categoryName: category.name,
                toSelectFeedstock: _vm.toSelectFeedstock
              }
            })}}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(category.icon)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(category.name)}})],1),_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }